import React from "react";

// Components
import Helmet from "react-helmet";

// Helpers
import * as gen from "./generators";
import { StaticQuery, graphql } from "gatsby";

// Types
import { FluidObject } from "gatsby-image";
import { Query, JSONLDProps } from "./types";

export type SEOProps = {
  /**
   * Title of the page. This will replace the title of the tab.
   */
  readonly title: string;

  /**
   * Image which will be used as banner
   */
  readonly banner?: FluidObject;

  /**
   * Description of the page. This is what will be shown when a link is previewed
   * and in the google description.
   */
  readonly description?: string;

  /**
   * Path of the page.
   *
   * @example
   * "/blog/gestion-de-documents"
   */
  readonly pathname: string;

  /**
   * Should be true if the page is an article page.
   *
   * @default false
   */
  readonly article?: boolean;

  /**
   * Extensions for the config/site.js
   */
  readonly siteMdExt?: Partial<JSONLDProps>;
};

const SEO = (props: SEOProps): JSX.Element => (
  <StaticQuery
    query={query}
    render={({ site: { buildTime, siteMetadata: md } }: Query) => {
      const seo: JSONLDProps["seo"] = {
        title: props.title || md.defaultTitle,
        description: props.description || md.defaultDescription,
        image: `${md.siteUrl}${props.banner?.src || "/" + md.defaultBanner}`,
        url: `${md.siteUrl}${props.pathname || "/"}`,
      };

      const schemaOrgJSONLD = !props.article
        ? [gen.WebsiteJSONLD({ seo, buildTime, ...md, ...props.siteMdExt })]
        : [
            gen.BlogPostingJSONLD({
              seo,
              buildTime,
              ...md,
              ...props.siteMdExt,
            }),
          ];

      return (
        <>
          <Helmet title={seo.title}>
            <html lang={md.siteLanguage} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="apple-mobile-web-app-title" content={md.shortName} />
            <meta name="application-name" content={md.shortName} />
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
            {props.siteMdExt?.author ? (
              <meta name="author" content={props.siteMdExt.author} />
            ) : null}

            {/* OpenGraph  */}
            <meta property="og:url" content={seo.url} />
            <meta
              property="og:type"
              content={props.article ? "article" : "website"}
            />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={md.twitter} />
            <meta name="twitter:site" content={md.twitter} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
          </Helmet>
        </>
      );
    }}
  />
);
export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        titleAlt
        shortName
        author
        siteLanguage
        logo
        siteUrl
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
        twitter
      }
    }
  }
`;
