// Types
import { BlogPosting, WebSite, WithContext } from "schema-dts";
import { JSONLDProps } from "./types";

export function WebsiteJSONLD({
  seo,
  ...md
}: JSONLDProps): Omit<WithContext<WebSite>, "@type" | "@context"> {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": md.siteUrl,
    url: seo.url,
    name: seo.title,
    alternateName: md.titleAlt || "",
    author: md.author,
    dateCreated: md.dateCreated,
    datePublished: md.datePublished,
  };
}

export function BlogPostingJSONLD({
  seo,
  titleAlt,
  buildTime,
  author,
  siteUrl,
  pathPrefix,
  logo,
}: JSONLDProps): Omit<WithContext<BlogPosting>, "@type" | "@context"> {
  const realPrefix = pathPrefix === "/" ? "" : pathPrefix;

  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "@id": seo.url,
    url: seo.url,
    name: seo.title,
    alternateName: titleAlt || "",
    headline: seo.title,
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
    description: seo.description,
    datePublished: buildTime,
    dateModified: buildTime,
    author: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
      logo: {
        "@type": "ImageObject",
        url: siteUrl + realPrefix + logo,
      },
    },
    isPartOf: siteUrl,
    mainEntityOfPage: {
      "@type": "WebSite",
      "@id": siteUrl,
    },
  };
}
